import { useEffect, useState } from 'react'
import {
  BiChevronDown,
  BiChevronUp,
  BiHelpCircle,
  BiHomeAlt,
  BiLogOutCircle,
} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  Divider,
  Hide,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Text,
} from '@chakra-ui/react'

import { CONTACT_US } from '~shared/constants/links'

import { useAdminAuth } from '../auth'

import { NavMenuItem } from './NavMenuItem'

export const UserMenu = () => {
  const { adminUser, logout } = useAdminAuth()
  const navigate = useNavigate()

  const [currentUrl, setCurrentUrl] = useState<string>()

  useEffect(function getCurrentUrl() {
    setCurrentUrl(window.location.href)
  }, [])

  return (
    <Menu isLazy>
      {({ isOpen }) => (
        <>
          <HStack spacing={{ md: '20px', base: 2 }}>
            <Button
              variant="ghost"
              border="none"
              onClick={() => navigate('/admin/collections')}
              _hover={{ textColor: 'green' }}
              color={
                currentUrl?.includes('/admin/collections') ?? false
                  ? 'interaction.links.neutral-hover'
                  : 'grey.300'
              }
            >
              <Hide below="md">All Collections</Hide>
              <Show below="md">
                <BiHomeAlt size={20} />
              </Show>
            </Button>
            <Link
              href={CONTACT_US}
              isExternal
              textColor={'interaction.links.neutral-hover'}
              _hover={{ textColor: 'green' }}
              textDecoration="none"
            >
              <Button variant="ghost" border="none">
                <Hide below="md">Get Help</Hide>
                <Show below="md">
                  <BiHelpCircle size={20} />
                </Show>
              </Button>
            </Link>
            <MenuButton name="menu-button">
              <HStack spacing={1} alignItems="center">
                <Avatar
                  w="30px"
                  h="30px"
                  name={adminUser?.email}
                  getInitials={(name) => name.charAt(0)}
                />
                <Icon
                  fontSize="1.5rem"
                  as={isOpen ? BiChevronUp : BiChevronDown}
                />
              </HStack>
            </MenuButton>
          </HStack>
          <MenuList py={0} fontSize="sm">
            <NavMenuItem closeOnSelect={false}>
              <Text userSelect="text">{adminUser?.email}</Text>
            </NavMenuItem>
            <Divider />
            <NavMenuItem icon={BiLogOutCircle} onClick={() => logout()}>
              Logout
            </NavMenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
