interface HighlightProps {
  text: string
  query: string
}

export const Highlight = ({ text, query }: HighlightProps) => {
  if (!query) return <span>{text}</span>

  const regex = new RegExp(`(${query})`, 'gi')
  const parts = text.split(regex)

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        ),
      )}
    </span>
  )
}
