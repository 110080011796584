import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { GetSubmissionDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'

import { useCampaign } from '../hooks/campaigns.hooks'
import { useGetSubmissions } from '../hooks/submissions.hooks'

import { CreateSubmissionLinkStepOne } from './CreateSubmissionLinkStepOne'
import { CreateSubmissionLinkStepTwo } from './CreateSubmissionLinkStepTwo'

export const CreateSubmissionPage = (): JSX.Element => {
  const [step, setStep] = useState(0)
  const { adminUser } = useAdminUser()
  const { campaign, isCampaignLoading } = useCampaign()
  const { submissions } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId: campaign?.id,
  })

  const [newSubmission, setNewSubmission] = useState<GetSubmissionDto | null>(
    null,
  )
  const navigate = useNavigate()

  if (!campaign || !submissions) {
    navigate('/admin/collections')
  }

  const getStepComponent = () => {
    switch (step) {
      case 0:
        if (campaign && submissions) {
          return (
            <CreateSubmissionLinkStepOne
              setStep={setStep}
              campaign={campaign}
              submissions={submissions}
              setNewSubmission={setNewSubmission}
            />
          )
        }
        return <></>
      case 1:
        if (campaign && newSubmission) {
          return (
            <CreateSubmissionLinkStepTwo
              campaign={campaign}
              submission={newSubmission}
              setStep={setStep}
            />
          )
        }
        return <></>
    }
  }

  return <>{getStepComponent()}</>
}
