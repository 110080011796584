import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Button, Image, Text, useDisclosure, VStack } from '@chakra-ui/react'

import { GetCampaignDto, GetSubmissionDto } from '~shared/dtos'

import DocumentsFlying from '~/assets/DocumentsFlying.svg'

import { BasicAdminPage } from '../BasicAdminPage'
import { CreateSubmissionModal } from '../Submissions/CreateSubmissionModal'

export interface EmptyCollectionProps {
  campaign: GetCampaignDto
  submissions: GetSubmissionDto[] | undefined
  onCreate: (submission: GetSubmissionDto) => void
}

export const EmptyCollection = ({
  campaign,
  submissions,
  onCreate,
}: EmptyCollectionProps): JSX.Element => {
  const createSubmissionDisclosure = useDisclosure()
  const navigate = useNavigate()
  return (
    <BasicAdminPage
      title={campaign.name}
      backButtonProps={{
        label: 'Back to all collections',
        onClick: () => navigate(`/admin/collections/`),
      }}
    >
      <VStack spacing="20px" justify={'center'} height="55vh">
        <Image src={DocumentsFlying} mb="24px" />
        <VStack spacing="12px">
          <Text textStyle="h6" color="base.content.default">
            You don’t have any submission link yet
          </Text>
          <Text textStyle="h5" color="brand.primary.500">
            Create links to start collecting files
          </Text>
        </VStack>
        <Button
          leftIcon={<BiPlus fontSize="20px" />}
          justifyContent="start"
          onClick={() =>
            navigate(`/admin/collections/${campaign.id}/create-submission`)
          }
          fontSize={'16px'}
        >
          Create submission link
        </Button>
        {submissions && (
          <CreateSubmissionModal
            {...createSubmissionDisclosure}
            campaign={campaign}
            submissions={submissions}
            onCreate={onCreate}
          />
        )}
      </VStack>
    </BasicAdminPage>
  )
}
