import LoadingAnimation from './FullScreenLoading'

export const CreateCampaignFinalStep = (): JSX.Element => {
  return (
    <LoadingAnimation />
    // this is the code for new figma, but Xian wasn't sure, coded it out anyways, keeping it here
    // replacing with animation page for now
    // <VStack spacing="20px">
    //     <Image src={SubmissionComplete} mb="24px" />
    //     <VStack spacing="12px">
    //         <Text textStyle="h2" color="base.content.default">
    //             Collection successfully created!
    //         </Text>
    //         <Text textStyle="body-1" color="brand.primary.500">
    //             To begin collection, you will need to create and share submission link
    //         </Text>
    //     </VStack>
    //     <Button
    //         leftIcon={<BiPlus fontSize="20px" />}
    //         justifyContent="start"
    //         onClick={() => navigate('/admin/collections/create')}
    //         fontSize={'16px'}
    //     >
    //         Go to collection
    //     </Button>
    // </VStack>
  )
}
