import { useNavigate } from 'react-router-dom'
import { Image, Text, useDisclosure, VStack } from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import DocumentsFlying from '~/assets/DocumentsFlying.svg'

import { BasicAdminPage } from '../BasicAdminPage'
import { ShareSubmissionModal } from '../Submissions/ShareSubmissionModal'
import { SubmissionActionButtons } from '../Submissions/SubmissionActionButtons'

export interface EmptySubmissionLinkProps {
  submission: GetSubmissionDto
}

export const EmptySubmissionLink = ({
  submission,
}: EmptySubmissionLinkProps): JSX.Element => {
  const shareDisclosure = useDisclosure()
  const navigate = useNavigate()
  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )
  return (
    <BasicAdminPage
      title={submission.internalId}
      buttons={
        <SubmissionActionButtons
          variant="outline"
          colorScheme="green"
          submission={submission}
          onShare={shareDisclosure.onOpen}
          onEdit={() =>
            navigate(
              `/admin/collections/${submission.campaignId}/submission/${submission.id}/edit`,
            )
          }
          documentSize={documentSize}
        />
      }
      backButtonProps={{
        label: 'Back to collection',
        onClick: () => navigate(`/admin/collections/${submission.campaignId}`),
      }}
    >
      <VStack spacing="20px" justify={'center'} height="55vh">
        <Image src={DocumentsFlying} mb="24px" />
        <Text textStyle="h6" color="base.content.default">
          You don’t have any files yet
        </Text>
      </VStack>
      <ShareSubmissionModal {...shareDisclosure} submission={submission} />
    </BasicAdminPage>
  )
}
