import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  Infobox,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS } from '~shared/constants/submissions'
import { UpdateSubmissionReq } from '~shared/dtos'
import { isSubmissionLinkExpiryDateValid } from '~shared/utils/submissions'

import { useAdminAuth } from '~/features/auth'
import { CampaignBaseLayout } from '~/features/campaign/components/CampaignBaseLayout'
import { RichTextEditor } from '~/features/richtext'
import { useToast } from '~/hooks/useToast'

import {
  useSubmission,
  useSubmissionId,
  useUpdateSubmissionMutation,
} from '../hooks/submissions.hooks'

export const EditSubmissionLinkPage = (): JSX.Element => {
  const toast = useToast()
  const navigate = useNavigate()
  const { adminUser } = useAdminAuth()

  const { submissionId } = useSubmissionId()

  const { submission, isSubmissionLoading } = useSubmission(submissionId)

  const { mutateAsync, isLoading } = useUpdateSubmissionMutation({
    userId: adminUser?.id,
    submissionId: submission?.id ?? 0,
  })

  const formMethods = useForm<
    Pick<UpdateSubmissionReq, 'expiresAt' | 'instructions'>
  >({
    mode: 'onChange',
    defaultValues: {
      expiresAt: dayjs(submission?.expiresAt).toDate(),
      instructions: submission?.instructions,
    },
  })

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = formMethods

  const rules = {
    required: 'This field is required',
  }

  // Do not allow link expiry date to be updated once the submission link expiry has passed
  const isUpdatingLinkExpiryDateAllowed =
    dayjs(submission?.expiresAt).toDate() < dayjs().startOf('day').toDate()

  const onSubmit = handleSubmit((formValues) => {
    const { expiresAt, ...formValuesExceptExpiresAt } = formValues
    const updateSubmissionReq: UpdateSubmissionReq =
      isUpdatingLinkExpiryDateAllowed ? formValuesExceptExpiresAt : formValues

    return mutateAsync(updateSubmissionReq, {
      onSuccess: () => {
        reset({
          expiresAt: formValues.expiresAt,
          instructions: formValues.instructions,
        })
        toast({
          status: 'success',
          description: 'Submission link updated',
        })
        navigate(`/admin/collections/${submission?.campaignId ?? 0}`)
      },
      onError: () => {
        toast({
          status: 'error',
          description:
            'There was an error creating the link. Please try again later.',
        })
      },
    })
  })

  if (!submission || isSubmissionLoading) return <Spinner />

  return (
    <FormProvider {...formMethods}>
      <CampaignBaseLayout
        title="Edit submission link"
        backButtonProps={{
          label: 'Back',
          onClick: () =>
            navigate(`/admin/collections/${submission.campaignId}`),
        }}
        buttons={
          <VStack width={'100%'} spacing="16px">
            <Button
              w="100%"
              onClick={() => {
                onSubmit()
              }}
              isLoading={isLoading}
              isDisabled={!!errors.instructions || !!errors.expiresAt}
            >
              Save
            </Button>
            <Button
              variant={'clear'}
              w="100%"
              onClick={() =>
                navigate(`/admin/collections/${submission.campaignId}`)
              }
            >
              Cancel
            </Button>
          </VStack>
        }
      >
        <VStack spacing={10} align="stretch">
          <FormControl isInvalid={!!errors.expiresAt}>
            <Text textStyle={'subhead-1'}>{'Link expiry date'}</Text>
            <Text
              textStyle={'body-2'}
              color={'base.content.medium'}
              mb="12px"
              fontSize={'14px'}
            >
              The link will be open for file submissions till this date
              (inclusive). Once the submission link has expired, you will not be
              able to change this date.
            </Text>
            <Infobox
              border="1px"
              borderColor="yellow.300"
              borderRadius="md"
              variant="warning"
              w="100%"
              mb="12px"
              icon={<WarningIcon color="yellow.400" fontSize="sm" />}
            >
              <Text textStyle="body-1" pt="2px">
                All files collected on this link will be deleted after{' '}
                {FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS} days of this date.
              </Text>
            </Infobox>
            <Controller
              name="expiresAt"
              rules={{
                required: 'Please enter a date',
                validate: {
                  pattern: (date: Date) => {
                    if (
                      !isUpdatingLinkExpiryDateAllowed &&
                      !isSubmissionLinkExpiryDateValid(date)
                    )
                      return 'Date cannot be in the past'
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  onChange={onChange}
                  value={value}
                  name={'expiry-datepicker'}
                  disabled={isUpdatingLinkExpiryDateAllowed}
                  isDateUnavailable={(date) =>
                    !isSubmissionLinkExpiryDateValid(date)
                  }
                />
              )}
            />
            <FormErrorMessage>{errors.expiresAt?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.instructions}>
            <HStack>
              <Text textStyle={'subhead-1'}>Instructions</Text>
              <Text textStyle={'body-2'}>(Optional)</Text>
            </HStack>
            <Text
              textStyle={'body-2'}
              color={'base.content.medium'}
              mb="12px"
              fontSize={'14px'}
            >
              Include your requirements or any other information you would like
              the receiver to know here.
            </Text>
            <Controller
              name="instructions"
              rules={rules}
              render={({ field: { value } }) => (
                <RichTextEditor
                  content={value as string}
                  setContent={(c) =>
                    setValue('instructions', c, {
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
            <FormErrorMessage>{errors.instructions?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
      </CampaignBaseLayout>
    </FormProvider>
  )
}
