import {
  BiDotsVerticalRounded,
  BiLink,
  BiPencil,
  BiTrash,
} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { MAX_DOWLOAD_FILE_SIZE_IN_BYTES } from '~/constants/config'
import { DownloadAllButton } from '~/features/download'

export const SubmissionActionButtons = ({
  submission,
  onShare,
  onEdit,
  documentSize,
  isDisabled = false,
  ...rest
}: {
  submission: GetSubmissionDto
  onShare: (s: GetSubmissionDto) => void
  onEdit: (s: GetSubmissionDto) => void
  documentSize: number
  isDisabled?: boolean
} & ButtonGroupProps) => {
  const navigate = useNavigate()
  return (
    <ButtonGroup {...rest} colorScheme="green">
      <Tooltip label="Share">
        <Button
          aria-label="share"
          leftIcon={<BiLink />}
          onClick={(e) => {
            e.stopPropagation()
            onShare(submission)
          }}
          isDisabled={isDisabled}
        >
          Share link
        </Button>
      </Tooltip>
      <Tooltip
        label={
          documentSize > MAX_DOWLOAD_FILE_SIZE_IN_BYTES
            ? 'Your total file size exceeds 1GB. Please download the files individually to prevent system overload.'
            : 'Download all'
        }
      >
        <span>
          <DownloadAllButton
            campaignId={submission.campaignId}
            campaignName={submission.campaignName}
            campaignPublicKey={submission.campaignPublicKey}
            documents={submission.documents}
            isDisabled={
              isDisabled || documentSize > MAX_DOWLOAD_FILE_SIZE_IN_BYTES
            }
          />
        </span>
      </Tooltip>
      <span>
        <Menu placement="bottom-end">
          <MenuButton
            as={Button}
            onClick={(e) => e.stopPropagation()}
            padding={0}
          >
            <Icon as={BiDotsVerticalRounded} boxSize={6} />
          </MenuButton>
          <MenuList mt={-3}>
            <MenuItem
              onClick={() =>
                navigate(
                  `/admin/collections/${submission.campaignId}/submission/${submission.id}/edit`,
                )
              }
            >
              <HStack spacing="12px">
                <Icon as={BiPencil} boxSize={'20px'} />
                <Text textStyle="body-1">Edit link</Text>
              </HStack>
            </MenuItem>
            <MenuItem
              isDisabled={!!submission.documents}
              onClick={async (e) => {
                // TODO: complete this function
                e.stopPropagation()
              }}
              color="interaction.critical.default"
            >
              <HStack spacing="12px">
                <Icon as={BiTrash} boxSize={'20px'} />
                <Text textStyle="body-1">Delete all files</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </span>
    </ButtonGroup>
  )
}
