import { Outlet } from 'react-router-dom'
import { Box, VStack } from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import { Navbar } from '~/features/navbar'

export const AdminLayoutV2 = () => {
  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Navbar />
      <Box px="admin-app-px">
        <VStack
          align="stretch"
          spacing={'24px'}
          backgroundColor={'blue.50'}
          mx="32px"
          mb="32px"
          py="40px"
          borderRadius={'10px'}
          minH="admin-content-min-height"
        >
          <Outlet />
        </VStack>
      </Box>
      <AppFooter containerProps={{ px: '3rem' }} />
    </VStack>
  )
}
