import { useState } from 'react'
import { BiDownload, BiLink } from 'react-icons/bi'
import { Button, useDisclosure } from '@chakra-ui/react'
import { IconButtonProps } from '@opengovsg/design-system-react'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'
import { useCampaignPrivateKey } from '~/features/dashboard'
import { DownloadModal } from '~/features/download'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload, DownloadStatus } from '~/utils/download'

export interface DownloadAllButtonProps {
  campaignId: number
  campaignName: string
  campaignPublicKey: string
  documents: GetDocumentDto[]
  isDisabled?: boolean
}

export const DownloadAllButton = ({
  campaignId,
  campaignName,
  campaignPublicKey,
  documents,
  isDisabled = false,
}: DownloadAllButtonProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { adminUser } = useAdminUser()
  const campaignPrivateKey = useCampaignPrivateKey(campaignId)
  const toast = useToast()
  const [progress, setProgress] = useState({
    progress: 0,
    info: DownloadStatus.INITIALIZING,
  })

  const handleClick = async () => {
    if (campaignPrivateKey === null) {
      onOpen()
      return
    }
    try {
      await decryptAndDownload(
        documents,
        campaignPrivateKey,
        setProgress,
        adminUser?.id,
      )
    } catch (err) {
      toast({
        status: 'error',
        description:
          err instanceof Error ? err.message : 'Something went wrong.',
      })
    }
  }

  return (
    <>
      <Button
        aria-label={'Download all'}
        isDisabled={isDisabled || documents.length === 0}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleClick()
        }}
        leftIcon={<BiDownload />}
      >
        Download all
      </Button>
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={campaignId}
        campaignName={campaignName}
        campaignPublicKey={campaignPublicKey}
        documents={documents}
        setProgress={setProgress}
      />
    </>
  )
}
