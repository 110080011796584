import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Button, Image, Text, VStack } from '@chakra-ui/react'

import DocumentFolder from '~/assets/DocumentFolder.svg'

export const EmptyDashboard = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <VStack spacing="20px" justify={'center'} height="70vh">
      <Image src={DocumentFolder} mb="24px" />
      <VStack spacing="12px">
        <Text textStyle="h6" color="base.content.default">
          You don’t have any collections yet
        </Text>
        <Text textStyle="h5" color="brand.primary.500">
          Create a campaign to generate submission link
        </Text>
      </VStack>
      <Button
        leftIcon={<BiPlus fontSize="20px" />}
        justifyContent="start"
        onClick={() => navigate('/admin/collections/create')}
        fontSize={'16px'}
      >
        Create collection
      </Button>
    </VStack>
  )
}
