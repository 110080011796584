import React, { useEffect, useState } from 'react'
import { Box, Flex, keyframes, Spinner, Text, VStack } from '@chakra-ui/react'

// Keyframe for bouncing spinner effect
const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`

// Keyframe for fading in the text
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const LoadingAnimation: React.FC = () => {
  const [textIndex, setTextIndex] = useState(0)

  const messages = [
    'Creating your collection...',
    'Making space for storing docs...',
    'Redirecting to the collection...',
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 1200) // Change text every 1.2 seconds

    return () => clearInterval(interval)
  }, [])

  return (
    <VStack spacing="20px" justify={'center'} height="70vh">
      <Flex
        direction="column"
        justify="center"
        align="center"
        bg="blue.50"
        p={8}
        width="400px"
        height="300px"
        animation={`${fadeIn} 0.5s ease-in-out`}
      >
        {/* Bouncing spinner */}
        <Box animation={`${bounce} 1.5s infinite ease-in-out`}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="brand.primary.500"
            emptyColor="brand.secondary.100"
            size="xl"
            mb={4}
          />
        </Box>

        {/* Fading in text */}
        <Text
          fontSize="lg"
          color="brand.primary.600"
          fontWeight="bold"
          animation={`${fadeIn} 0.8s ease-in-out`}
        >
          {messages[textIndex]}
        </Text>
      </Flex>
    </VStack>
  )
}

export default LoadingAnimation
