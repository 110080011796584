import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  DatePicker,
  FormErrorMessage,
  Infobox,
} from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import {
  FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS,
  SUBMISSION_ID_REGEX,
  SUBMISSION_ID_REGEX_ERROR,
  SUBMISSION_MAX_ID_LEN,
  SUBMISSION_MAX_ID_LEN_ERROR,
} from '~shared/constants/submissions'
import {
  CreateSubmissionReq,
  GetCampaignDto,
  GetSubmissionDto,
} from '~shared/dtos'
import { isSubmissionLinkExpiryDateValid } from '~shared/utils/submissions'

import { CampaignBaseLayout } from '~/features/campaign/components/CampaignBaseLayout'
import { RichTextEditor } from '~/features/richtext'
import { useToast } from '~/hooks/useToast'

import { useCreateSubmissionMutation } from '../hooks/submissions.hooks'

export interface CreateSubmissionLinkStepOneProps {
  setStep: (step: number) => void
  submissions: GetSubmissionDto[]
  campaign: GetCampaignDto
  setNewSubmission: (submission: GetSubmissionDto) => void
}
export const CreateSubmissionLinkStepOne = ({
  setStep,
  submissions,
  campaign,
  setNewSubmission,
}: CreateSubmissionLinkStepOneProps): JSX.Element => {
  const navigate = useNavigate()

  const toast = useToast()
  const formMethods = useForm<CreateSubmissionReq>({
    mode: 'onChange',
    defaultValues: {
      instructions: campaign?.defaultInstructions,
      expiresAt: dayjs().add(1, 'week').startOf('day').toDate(),
    },
  })
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isDirty },
  } = formMethods

  const createSubmissionMutation = useCreateSubmissionMutation(
    campaign?.userId,
    {
      onSuccess: (res: GetSubmissionDto) => {
        toast({
          status: 'success',
          description: 'Link created',
        })
        setNewSubmission(res)
        setStep(1)
      },
      onError: () => {
        toast({
          status: 'error',
          description:
            'There was an error creating the link. Please try again later.',
        })
      },
    },
  )

  const onSubmit = handleSubmit((values) => {
    createSubmissionMutation.mutate({
      ...values,
      campaignId: campaign.id,
      internalId: values.internalId.trim(),
    })
    reset()
  })

  return (
    <FormProvider {...formMethods}>
      <CampaignBaseLayout
        title="Create new submission link"
        backButtonProps={{
          label: 'Back',
          onClick: () => navigate(`/admin/collections/${campaign.id}`),
        }}
        buttons={
          <VStack width={'100%'} spacing="16px">
            <Button
              w="100%"
              onClick={() => {
                onSubmit()
              }}
              isDisabled={
                !!errors.internalId ||
                !!errors.expiresAt ||
                !!errors.instructions ||
                createSubmissionMutation.isLoading ||
                !isDirty
              }
            >
              Create link
            </Button>
            <Button
              variant={'clear'}
              w="100%"
              onClick={() => navigate(`/admin/collections/${campaign.id}`)}
            >
              Cancel
            </Button>
          </VStack>
        }
      >
        <VStack spacing={10} align="stretch">
          <VStack spacing={10} align="stretch">
            <FormControl isRequired isInvalid={!!errors.internalId}>
              <Text textStyle={'subhead-1'}>{'Unique identifier'}</Text>
              <Text
                textStyle={'body-2'}
                color={'base.content.medium'}
                mb="12px"
                fontSize={'14px'}
              >
                This may be a UIN or Case ID. Identifier will be used to
                organize your incoming documents.
              </Text>
              <Controller
                name="internalId"
                rules={{
                  required: 'Please enter an identifier',
                  maxLength: {
                    value: SUBMISSION_MAX_ID_LEN,
                    message: SUBMISSION_MAX_ID_LEN_ERROR,
                  },
                  validate: {
                    pattern: (value: string) => {
                      if (!SUBMISSION_ID_REGEX.test(value.trim()))
                        return SUBMISSION_ID_REGEX_ERROR
                      if (
                        submissions
                          .map((submission) => submission.internalId)
                          .includes(value.trim())
                      )
                        return 'A submission with this unique identifier already exists'
                    },
                  },
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <Input
                    ref={ref}
                    value={value}
                    placeholder="Enter UIN or Case ID"
                    onChange={onChange}
                    maxLength={SUBMISSION_MAX_ID_LEN}
                  />
                )}
              />
              <FormErrorMessage>{errors.internalId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.expiresAt}>
              <Text textStyle={'subhead-1'}>{'Link expiry date'}</Text>
              <Text
                textStyle={'body-2'}
                color={'base.content.medium'}
                mb="12px"
                fontSize={'14px'}
              >
                The link will be open for file submissions till this date
                (inclusive). Once the submission link has expired, you will not
                be able to change this date.
              </Text>

              <Infobox
                border="1px"
                borderColor="yellow.300"
                borderRadius="md"
                variant="warning"
                w="100%"
                mb="12px"
                icon={<WarningIcon color="yellow.400" fontSize="sm" />}
              >
                <Text textStyle="body-1" pt="2px">
                  All files collected on this link will be deleted after{' '}
                  {FILE_EXPIRY_AFTER_LINK_EXPIRY_DAYS} days of this date.
                </Text>
              </Infobox>
              <Controller
                name="expiresAt"
                control={control} // Needed to recognise value as Date
                rules={{
                  required: 'Please enter a date',
                  validate: {
                    pattern: (date: Date) => {
                      if (!isSubmissionLinkExpiryDateValid(date))
                        return 'Date cannot be in the past'
                    },
                  },
                }}
                render={({ field: { value } }) => (
                  <DatePicker
                    onChange={(date) => {
                      setValue('expiresAt', date ?? value, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }}
                    value={value}
                    name={'expiry-datepicker'}
                    isDateUnavailable={(date) =>
                      !isSubmissionLinkExpiryDateValid(date)
                    }
                  />
                )}
              />
              <FormErrorMessage>{errors.expiresAt?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.instructions}>
              <HStack>
                <Text textStyle={'subhead-1'}>Instructions</Text>
                <Text textStyle={'body-2'}>(Optional)</Text>
              </HStack>
              <Text
                textStyle={'body-2'}
                color={'base.content.medium'}
                mb="12px"
                fontSize={'14px'}
              >
                Include your requirements or any other information you would
                like the receiver to know here.
              </Text>

              <Controller
                name="instructions"
                render={({ field: { value } }) => (
                  <RichTextEditor
                    content={value as string}
                    setContent={(c) =>
                      setValue('instructions', c, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />
              <FormErrorMessage>
                {errors.instructions?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </VStack>
      </CampaignBaseLayout>
    </FormProvider>
  )
}
