import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BiMailSend } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  VStack,
} from '@chakra-ui/react'
import { FormLabel } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { GetCampaignDto, GetSubmissionDto } from '~shared/dtos'

import { CopyButton } from '~/components/CopyButton'
import { MultiEmailInput } from '~/components/MultiEmailInput'
import { HOSTNAME_WITH_PROTOCOL } from '~/constants/config'
import { useAdminAuth } from '~/features/auth'
import { CampaignBaseLayout } from '~/features/campaign/components/CampaignBaseLayout'
import { useToast } from '~/hooks/useToast'

import { useNotifySubmissionMutation } from '../hooks/submissions.hooks'

type CreateSubmissionLinkStepTwoProps = {
  submission: GetSubmissionDto
  campaign: GetCampaignDto
  setStep: (step: number) => void
}

type ShareSubmissionFormFields = {
  emails: string[]
}

export const CreateSubmissionLinkStepTwo = ({
  submission,
  campaign,
  setStep,
}: CreateSubmissionLinkStepTwoProps): JSX.Element => {
  const navigate = useNavigate()
  const toast = useToast()
  const { adminUser } = useAdminAuth()
  const link = `${HOSTNAME_WITH_PROTOCOL}/public/${submission.publicId}`

  const { mutateAsync: notify } = useNotifySubmissionMutation({
    userId: adminUser?.id,
    submissionId: submission.id,
  })

  const expiryHours = useMemo(
    () => dayjs(submission.expiresAt).endOf('day').diff(dayjs(), 'hours'),
    [submission.expiresAt],
  )

  const isExpired = useMemo(() => expiryHours <= 0, [expiryHours])

  const formMethods = useForm<ShareSubmissionFormFields>({
    mode: 'onChange',
  })
  const { handleSubmit, formState } = formMethods

  const onEmail = handleSubmit(async ({ emails }) => {
    await Promise.all(
      emails.map((email) =>
        notify(
          { email },
          {
            onSuccess: () => {
              toast({
                description: 'Submission link has been sent',
                status: 'success',
              })
            },
            onError: () => {
              toast({
                description: 'Something went wrong. Please try again.',
                status: 'error',
              })
            },
          },
        ),
      ),
    )
  })

  return (
    <CampaignBaseLayout
      title="Share submission link"
      backButtonProps={{
        label: 'Back',
        onClick: () => setStep(0),
      }}
      buttons={
        <VStack width={'100%'} spacing="16px">
          <Button
            w="100%"
            onClick={() => {
              setStep(0)
            }}
          >
            Create another link
          </Button>
          <Button
            variant={'clear'}
            w="100%"
            onClick={() => navigate(`/admin/collections/${campaign.id}`)}
          >
            Cancel
          </Button>
        </VStack>
      }
    >
      <VStack spacing={10} align="stretch">
        <VStack spacing={10} align="stretch">
          <FormProvider {...formMethods}>
            <VStack spacing={6}>
              <FormControl
                isRequired
                isInvalid={!!formState.errors.emails}
                isReadOnly={formState.isSubmitting}
              >
                <FormLabel
                  description={
                    isExpired
                      ? 'Edit the submission expiry date before sharing this link.'
                      : 'Enter the email addresses you would like to send the link to'
                  }
                  fontSize={{ base: '1.125rem', lg: '1rem' }}
                >
                  Send link via email
                </FormLabel>
                <HStack>
                  <MultiEmailInput name="emails" isDisabled={isExpired} />
                  <IconButton
                    isDisabled={!!formState.errors.emails || isExpired}
                    isLoading={formState.isSubmitting}
                    icon={<BiMailSend />}
                    aria-label="Send via email"
                    onClick={onEmail}
                  />
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={{ base: '1.125rem', lg: '1rem' }}>
                  or copy and share this link
                </FormLabel>
                <HStack mb="24px">
                  <Input isReadOnly value={link} isDisabled />
                  <CopyButton
                    stringToCopy={link}
                    aria-label="Copy field ID value"
                    variant="solid"
                  />
                </HStack>
              </FormControl>
            </VStack>
          </FormProvider>
        </VStack>
      </VStack>
    </CampaignBaseLayout>
  )
}
