import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiDownload } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  Code,
  HStack,
  Link,
  Text,
  Tooltip,
  useClipboard,
  VStack,
} from '@chakra-ui/react'
import { BxMailSend, IconButton } from '@opengovsg/design-system-react'
import dedent from 'dedent'

import { CreateCampaignReq, GetCampaignDto } from '~shared/dtos'

import { useAdminAuth } from '~/features/auth'
import { useCreateCampaignMutation } from '~/features/dashboard/hooks/campaigns.hooks'
import { ResponseError } from '~/types/error'
import { encryptionService } from '~/utils/encryption'

import { CampaignBaseLayout } from './CampaignBaseLayout'

export interface CreateCampaignStepTwoProps {
  setStep: (step: number) => void
}

export const CreateCampaignStepTwo = ({
  setStep,
}: CreateCampaignStepTwoProps): JSX.Element => {
  const { adminUser } = useAdminAuth()
  const keyPair = useRef(encryptionService.generateAsymmetricKeyPair())
  const privateKey = keyPair.current.privateKey

  const {
    watch,
    setError,
    reset,
    getValues,
    setValue: setValueForm,
  } = useFormContext<CreateCampaignReq>()
  const campaignName = watch('name').trim()

  const createCampaignMutation = useCreateCampaignMutation(adminUser?.id, {
    onSuccess: (res: GetCampaignDto) => {
      setTimeout(() => {
        navigate(`/admin/collections/${res.id}`)
      }, 4000)
    },
    onError: (err: ResponseError) => {
      setError('name', {
        type: 'custom',
        message: err.json.message,
      })
    },
  })

  const navigate = useNavigate()
  const [isCheckedItem, setCheckedItem] = useState<boolean>(false)
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const { hasCopied, onCopy, setValue } = useClipboard(privateKey)

  useEffect(() => {
    setValue(privateKey)
  }, [privateKey, setValue])

  const onDownloadKey = useCallback(() => {
    const blob = new Blob([privateKey], {
      type: 'text/plain;charset=utf-8',
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `Highway Collection Secret Key - ${campaignName}`
    link.href = url
    link.click()
    setIsDownloaded(true)
  }, [campaignName, privateKey])

  const mailToHref = useMemo(() => {
    const subject = ` Secret Key for ${campaignName} Collection`
    const body = dedent`
          Dear Collaborator,
  
          I am sharing my collection's secret key with you for safekeeping and backup. This is an important key that is needed to access all collection submissions.
  
          Collection Name: ${campaignName}
  
          Secret Key: ${privateKey}
  
          All you need to do is keep this email as a record, and please do not share this key with anyone else.
  
          Thank you for helping to safekeep my collection!`
    const href = `mailto:?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`
    return href
  }, [campaignName, privateKey])

  const handleClose = () => {
    setCheckedItem(false)
    setIsDownloaded(false)
    reset()
    keyPair.current = encryptionService.generateAsymmetricKeyPair()
  }

  const handleSubmit = () => {
    setValueForm('publicKey', keyPair.current.publicKey)
    setValueForm('name', getValues('name').trim())
    createCampaignMutation.mutate(getValues())
    handleClose()
  }

  return (
    <CampaignBaseLayout
      title="Download secret key to proceed"
      backButtonProps={{
        label: 'Back',
        onClick: () => setStep(0),
      }}
      buttons={
        <VStack width={'100%'} spacing="px">
          <Button
            w="100%"
            isDisabled={!isCheckedItem}
            onClick={() => {
              handleSubmit()
              setStep(2)
            }}
          >
            I have saved my secret key safely
          </Button>
          <Button
            variant={'clear'}
            w="100%"
            onClick={() => navigate(`/admin/collections/`)}
          >
            Cancel
          </Button>
        </VStack>
      }
    >
      <Box
        mx="8"
        my="6"
        p="32px"
        gap="24px"
        border="1px"
        borderColor="base.divider.medium"
        display="flex"
        flexDir="column"
        backgroundColor={'white'}
      >
        <Text as="span">
          You’ll need it every time you access your responses to this
          collection. If you lose it,{' '}
          <Text color="interaction.critical.default" as="span">
            all responses will be permanently lost.
          </Text>
          <br />
          <br />
          You can also{' '}
          <Link href={mailToHref} isExternal>
            email it
          </Link>{' '}
          for safekeeping.
        </Text>
        <HStack justifyContent="space-between">
          <Tooltip mt={0} label={hasCopied ? 'Copied!' : 'Copy key'}>
            <Code
              bg="interaction.sub-subtle.default"
              border="1px"
              borderColor="base.divider.strong"
              py="10px"
              px="2"
              cursor="pointer"
              _hover={{
                bg: 'interaction.sub-subtle.hover',
              }}
              onClick={onCopy}
              isTruncated={true}
              fontSize="sm"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {privateKey}
            </Code>
          </Tooltip>
          <Button rightIcon={<BiDownload />} onClick={onDownloadKey}>
            Download
          </Button>
          <IconButton
            aria-label="mail-key"
            icon={<BxMailSend />}
            variant="outline"
            onClick={() => window.open(mailToHref)}
          />
        </HStack>
        {isDownloaded && (
          <Box gap="24px" display="flex">
            <Checkbox
              aria-label="Private key acknowledgement"
              onChange={(e) => setCheckedItem(e.target.checked)}
              size="sm"
              isFocusable={false}
              _focusWithin={{ outline: 'none' }}
            >
              If I lose my Secret Key, I cannot activate my collection or access
              any responses to it
            </Checkbox>
          </Box>
        )}
      </Box>
    </CampaignBaseLayout>
  )
}
