import { useMutation, useQueryClient } from '@tanstack/react-query'

import { GetSubmissionDto } from '~shared/dtos'

import { adminQueryKeys } from '~/constants/query-keys'
import { useToast } from '~/hooks/useToast'
import { api } from '~/lib/api'

import { useCampaignId } from './campaigns.hooks'

export const useDeleteDocument = (
  {
    userId,
    documentId,
  }: {
    userId?: number
    documentId: number
  },
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void
    onError?: () => void
  } = {},
) => {
  const queryClient = useQueryClient()
  const { campaignId } = useCampaignId()
  const toast = useToast()
  return useMutation(
    async (): Promise<GetSubmissionDto> => {
      return await api
        .url(`/users/${userId ?? ''}/documents/${documentId}`)
        .delete()
        .res()
    },
    {
      onSuccess: async () => {
        toast({
          status: 'success',
          description: 'Successfully deleted document.',
        })
        await queryClient.invalidateQueries(
          adminQueryKeys.submissions({ userId, campaignId }),
        )
        onSuccess?.()
      },
      onError: async () => {
        toast({
          status: 'error',
          description: 'Failed to delete document. Please try again later.',
        })
        onError?.()
      },
    },
  )
}
