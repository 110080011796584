import { ReactNode } from 'react'
import { FormProvider as RHFormProvider, useForm } from 'react-hook-form'

import { CreateCampaignReq } from '~shared/dtos'

interface FormProviderProps {
  children: ReactNode
}

export const FormProvider = ({ children }: FormProviderProps) => {
  const methods = useForm<CreateCampaignReq>({
    defaultValues: {
      name: '',
      defaultInstructions: '',
      publicKey: '',
    },
    mode: 'onChange',
  })

  return <RHFormProvider {...methods}>{children}</RHFormProvider>
}
