import { BiChevronLeft } from 'react-icons/bi'
import { HStack, Icon, Text, VStack } from '@chakra-ui/react'

interface BackButtonProps {
  label?: string
  onClick?: () => void
}

export interface CampaignBaseLayoutProps {
  title: string
  buttons?: React.ReactNode
  children: React.ReactNode
  backButtonProps?: BackButtonProps
}

export const CampaignBaseLayout = ({
  title,
  buttons,
  children,
  backButtonProps,
}: CampaignBaseLayoutProps): JSX.Element => {
  return (
    <VStack align="center">
      <VStack
        spacing={'24px'}
        align="left"
        px={'40px'}
        width={{ sm: '100%', md: '50%' }}
      >
        <VStack spacing={'32px'} align="left">
          {backButtonProps && (
            <HStack
              color="interaction.links.default"
              spacing={0}
              width="fit-content"
              onClick={backButtonProps.onClick}
              _hover={{ cursor: 'pointer' }}
              aria-label="back-to-collections"
            >
              <Icon as={BiChevronLeft} boxSize={5} />
              <Text textStyle="subhead-1" noOfLines={[1, 2, 3]}>
                {backButtonProps.label}
              </Text>
            </HStack>
          )}
          <Text
            textStyle="h2"
            noOfLines={[1, 2, 3]}
            color={'grey.900'}
            fontSize={'36px'}
            fontFamily={'Inter'}
          >
            {title}
          </Text>
        </VStack>
        {children}
        {buttons}
      </VStack>
    </VStack>
  )
}
