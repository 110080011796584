import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  VStack,
} from '@chakra-ui/react'
import { FormErrorMessage, FormLabel } from '@opengovsg/design-system-react'

import {
  CAMPAIGN_MAX_NAME_LEN,
  CAMPAIGN_MAX_NAME_LEN_ERROR,
  CAMPAIGN_NAME_REGEX,
  CAMPAIGN_NAME_REGEX_ERROR,
} from '~shared/constants/campaigns'
import { CreateCampaignReq } from '~shared/dtos'

import { CampaignBaseLayout } from './CampaignBaseLayout'

export interface CreateCampaignStepOneProps {
  setStep: (step: number) => void
}
export const CreateCampaignStepOne = ({
  setStep,
}: CreateCampaignStepOneProps): JSX.Element => {
  const navigate = useNavigate()

  const {
    formState: { errors, isDirty },
    register,
    watch,
  } = useFormContext<CreateCampaignReq>()

  return (
    <CampaignBaseLayout
      title="Create new collection"
      backButtonProps={{
        label: 'Back',
        onClick: () => navigate(`/admin/collections/`),
      }}
      buttons={
        <VStack width={'100%'} spacing="16px">
          <Button
            w="100%"
            onClick={() => {
              setStep(1)
            }}
            isDisabled={!!errors.name || !isDirty}
          >
            Next
          </Button>
          <Button
            variant={'clear'}
            w="100%"
            onClick={() => navigate(`/admin/collections/`)}
          >
            Cancel
          </Button>
        </VStack>
      }
    >
      <VStack spacing={10} align="stretch">
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>{'Collection Name'}</FormLabel>
          <Input
            placeholder="Enter name"
            maxLength={CAMPAIGN_MAX_NAME_LEN}
            {...register('name', {
              required: 'Please enter a collection name',
              maxLength: {
                value: CAMPAIGN_MAX_NAME_LEN,
                message: CAMPAIGN_MAX_NAME_LEN_ERROR,
              },
              validate: (value) => {
                if (!CAMPAIGN_NAME_REGEX.test(value.trim()))
                  return CAMPAIGN_NAME_REGEX_ERROR
              },
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          <FormHelperText>
            {`${CAMPAIGN_MAX_NAME_LEN - (watch('name')?.length || 0)}
                    characters left`}
          </FormHelperText>
        </FormControl>
      </VStack>
    </CampaignBaseLayout>
  )
}
