import { Navigate, RouteObject } from 'react-router-dom'

import { routes } from '~/constants/routes'
import { AdminProtectedRoute, LoginPage } from '~/features/auth'
import { CampaignPage } from '~/features/campaign/CampaignPage'
import { CreateCampaignPage } from '~/features/campaign/CreateCampaignPage'
import { DashboardPageV2 } from '~/features/dashboard'
import { SubmissionPage } from '~/features/dashboard/SubmissionPage'
import { CreateSubmissionPage } from '~/features/dashboard/Submissions/CreateSubmissionPage'
import { EditSubmissionLinkPage } from '~/features/dashboard/Submissions/EditSubmissionLinkPage'
import { AdminLayoutV2 } from '~/layouts/AdminLayout/AdminLayoutV2'

export const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="collections" />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'collections',
    element: (
      <AdminProtectedRoute>
        <AdminLayoutV2 />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPageV2 />,
      },
    ],
  },
  {
    path: routes.collections,
    element: (
      <AdminProtectedRoute>
        <AdminLayoutV2 />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPageV2 />,
      },
      {
        path: 'create',
        element: <CreateCampaignPage />,
      },
    ],
  },
  {
    path: `${routes.collections}/:campaignId`,
    element: (
      <AdminProtectedRoute>
        <AdminLayoutV2 />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <CampaignPage />,
      },
      {
        path: 'submission/:submissionId',
        element: <SubmissionPage />,
      },
      {
        path: 'submission/:submissionId/edit',
        element: <EditSubmissionLinkPage />,
      },
      {
        path: 'create-submission',
        element: <CreateSubmissionPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={routes.collections} />,
  },
]
