import { useState } from 'react'

import { CreateCampaignFinalStep } from './components/CreateCampaignFinalStep'
import { CreateCampaignStepOne } from './components/CreateCampaignStepOne'
import { CreateCampaignStepTwo } from './components/CreateCampaignStepTwo'
import { FormProvider } from './FormContext'

export const CreateCampaignPage = (): JSX.Element => {
  const [step, setStep] = useState(0)

  const getStepComponent = () => {
    switch (step) {
      case 0:
        return <CreateCampaignStepOne setStep={setStep} />
      case 1:
        return <CreateCampaignStepTwo setStep={setStep} />
      case 2:
        return <CreateCampaignFinalStep />
    }
  }

  return <FormProvider>{getStepComponent()}</FormProvider>
}
