import { BiDownload } from 'react-icons/bi'
import { Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconButton, IconButtonProps } from '@opengovsg/design-system-react'

import { GetDocumentDto } from '~shared/dtos'

import { useAdminUser } from '~/features/auth/hooks'
import { useCampaignPrivateKey } from '~/features/dashboard'
import { DownloadModal } from '~/features/download'
import { useToast } from '~/hooks/useToast'
import { decryptAndDownload, DownloadStatus } from '~/utils/download'

export interface DownloadButtonProps {
  campaignId: number
  campaignName: string
  campaignPublicKey: string
  documents: GetDocumentDto[]
  setProgressInfo: (progressInfo: {
    progress: number
    info: DownloadStatus
  }) => void
  variant?: IconButtonProps['variant']
  isDisabled?: boolean
}

export const DownloadButton = ({
  campaignId,
  campaignName,
  campaignPublicKey,
  documents,
  setProgressInfo,
  variant,
  isDisabled = false,
}: DownloadButtonProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { adminUser } = useAdminUser()
  const campaignPrivateKey = useCampaignPrivateKey(campaignId)
  const toast = useToast()

  const handleClick = async () => {
    if (campaignPrivateKey === null) {
      onOpen()
      return
    }
    try {
      await decryptAndDownload(
        documents,
        campaignPrivateKey,
        setProgressInfo,
        adminUser?.id,
      )
    } catch (err) {
      toast({
        status: 'error',
        description:
          err instanceof Error ? err.message : 'Something went wrong.',
      })
    }
  }

  return (
    <>
      <Tooltip
        label={
          isDisabled
            ? "You can download the document once it has been scanned for viruses or if it hasn't been recently downloaded."
            : `Download ${documents.length === 1 ? documents[0].name : 'all'}`
        }
        placement="top"
      >
        <IconButton
          aria-label={`Download ${
            documents.length === 1 ? documents[0].name : 'all'
          }`}
          icon={<BiDownload />}
          size="md"
          {...(variant && { variant })}
          isDisabled={isDisabled || documents.length === 0}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleClick()
          }}
        />
      </Tooltip>
      <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={campaignId}
        campaignName={campaignName}
        campaignPublicKey={campaignPublicKey}
        documents={documents}
        setProgress={setProgressInfo}
      />
    </>
  )
}
