import { Divider, Spinner, VStack } from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { useAdminAuth } from '~/features/auth'

import { useCampaignId } from '../hooks/campaigns.hooks'
import { useGetSubmissions } from '../hooks/submissions.hooks'

import { Submission, SubmissionHeader } from './Submission'
export const Submissions = ({
  onShare,
}: {
  onShare: (s: GetSubmissionDto) => void
}) => {
  const { adminUser } = useAdminAuth()
  const { campaignId } = useCampaignId()
  const { submissions, isSubmissionsLoading } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId,
  })

  if (isSubmissionsLoading || !submissions) return <Spinner />

  return (
    <VStack align="stretch" spacing={6}>
      <VStack align="stretch" spacing={0} divider={<Divider />}>
        <SubmissionHeader />
        {submissions.map((s) => (
          <Submission key={s.id} submission={s} onShare={onShare} />
        ))}
      </VStack>
    </VStack>
  )
}
